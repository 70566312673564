<template>
  <form-tabs>
    <form-tab tab-title="Contact Information">
      <form-contact-info />
    </form-tab>

    <form-tab icon="" tab-title="Basic Information">
      <form-webservice-basic-info />
    </form-tab>

    <form-tab icon="" tab-title="Website Attributes">
      <form-webservice-additional-info />
    </form-tab>
  </form-tabs>
</template>

<script>
import FormWebserviceBasicInfo from '../../components/forms/FormWebserviceBasicInfo.vue'
import FormWebserviceAdditionalInfo from '../../components/forms/FormWebserviceAdditionalInfo.vue'

export default {
  components: {
    FormWebserviceBasicInfo,
    FormWebserviceAdditionalInfo
  }
}
</script>
