<template>
  <div class="step-form">
    <div class="mb-1 text-center">
      <h2 class="title ff-arial fs-24 fw-600">Website Attributes</h2>
    </div>
    <form>
      <base-input
        name="favorite-websites"
        label="What are three of your favorite websites? Please separate with comma (,). *"
        v-model="favoriteSites"
        rules="required"
      />

      <base-input
        name="portray-websites"
        label="What image, look, or feel do you want your site to portray?"
        v-model="portrayOfYourSite"
      />

      <base-input
        name="competitor-websites"
        v-model="appreciateDislikeFeature"
      >
        <template v-slot:label>
          <label for="competitor-websites" class="form-label">
            Which features do you appreciate or dislike on your competitor's website?
          </label>
        </template>
      </base-input>

      <select-radio-2
        name="update-frequency"
        :items="[{name: 'Daily', value: 'daily'}, {name: 'Weekly', value: 'weekly'}, {name: 'Monthly', value: 'monthly'}, {name: 'Quarterly', value: 'quarterly'}, {name: 'Other', value: 'other'}]"
        v-model="updateFrequency"
      >
        How often do you require updates and new articles?
      </select-radio-2>
      
      <select-radio-2
        name="content-provider"
        :items="[{name: 'We will provide our own content', value: 'self'}, {name: 'StartABizzy.com will provide content', value: 'startabizzy'}]"
        v-model="contentProvider"
      >
        Who will provide content for the new website?
      </select-radio-2>

      <div class="mb-3">
        <label for="website-deadline" class="form-label">What is the deadline for the website?</label>
        <div class="d-flex">
          <select-month v-model="deadlineOfWebsiteMonth" name="deadlineOfWebsiteMonth" opt-class="triple-picker" />
          <select-date v-model="deadlineOfWebsiteDate" name="deadlineOfWebsiteDate" opt-class="triple-picker" rules="required" />
          <select-year v-model="deadlineOfWebsiteYear" name="deadlineOfWebsiteYear" opt-class="triple-picker" />
        </div>
      </div>

      <select-yes-no-radio name="have-guideline-of-image-brand" v-model="haveGuidelineOfLogoAndImage">
        Does your company have a logo/established image &amp; branding guidelines (e.g. fonts, color schemes etc)? *
      </select-yes-no-radio>

      <base-input
        name="guideline-of-image-brand"
        label="What is your logo/established image &amp; branding guidelines (e.g. fonts, color schemes etc)? *"
        v-model="guidelineOfLogoAndImage"
        v-if="haveGuidelineOfLogoAndImage"
        rules="required"
      />

      <base-input
        name="underliying-goal-of-website"
        label="What's the underlying goal of your website?"
        v-model="underlyingGoalOfWebsite"
      />
    </form>
  </div>
</template>


<script>
import store from '@/store'

export default {
  computed: {
    favoriteSites: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.favoriteSites
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.favoriteSites', value})
      }
    },
    portrayOfYourSite: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.portrayOfYourSite
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.portrayOfYourSite', value})
      }
    },
    appreciateDislikeFeature: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.appreciateDislikeFeature
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.appreciateDislikeFeature', value})
      }
    },

    updateFrequency: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.updateFrequency
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.updateFrequency', value})
      }
    },
    contentProvider: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.contentProvider
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.contentProvider', value})
      }
    },
    deadlineOfWebsiteYear: {
      get() {
        return `${new Date(store.state.forms.FormWebDevelopmentInfo.deadlineOfWebsite).getFullYear()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormWebDevelopmentInfo.deadlineOfWebsite)
        fullDate.setFullYear(parseInt(value))
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.deadlineOfWebsite', value: fullDate})
      }
    },
    deadlineOfWebsiteMonth: {
      get() {
        return `${new Date(store.state.forms.FormWebDevelopmentInfo.deadlineOfWebsite).getMonth()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormWebDevelopmentInfo.deadlineOfWebsite)
        fullDate.setMonth(parseInt(value))
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.deadlineOfWebsite', value: fullDate})
      }
    },
    deadlineOfWebsiteDate: {
      get() {
        return `${new Date(store.state.forms.FormWebDevelopmentInfo.deadlineOfWebsite).getDate()}`
      },
      set(value) {
        const fullDate = new Date(store.state.forms.FormWebDevelopmentInfo.deadlineOfWebsite)
        fullDate.setDate(parseInt(value))
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.deadlineOfWebsite', value: fullDate})
      }
    },
    haveGuidelineOfLogoAndImage: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.haveGuidelineOfLogoAndImage
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.haveGuidelineOfLogoAndImage', value})
      }
    },
    guidelineOfLogoAndImage: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.guidelineOfLogoAndImage
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.guidelineOfLogoAndImage', value})
      }
    },
    underlyingGoalOfWebsite: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.underlyingGoalOfWebsite
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.underlyingGoalOfWebsite', value})
      }
    },
  }
}
</script>
