<template>
    <div class="step-form">
      <div class="mb-1 text-center">
        <h2 class="title ff-arial fs-24 fw-600">Basic Information</h2>
      </div>
      <form>
        <base-input
          name="first-name"
          label="Business name *"
          v-model="bizName"
          rules="required"
        />
        
        <label for="business-description" class="form-label mb-0">Describe your business in a few sentences *</label>
        <textarea class="form-control mb-0" id="business-description" rows="5" v-model="bizDescription"></textarea>

        <select-radio name="is-build-new" opt-class="mb-0 mt-3"
          :items="[{name: 'Build a new website', value: true}, {name: 'Redesign', value: false}]"
          v-model="buildNew"
        >
          Do you want to build a new website or redesign an existing one?
        </select-radio>

        <!-- redesign information -->
        <base-input
          name="current-website"
          label="Current website URL (if applicable)"
          v-model="currentURL"
          v-if="!buildNew"
        />

        <base-input
          name="hosting-provider"
          label="Who is your web hosting provider?"
          v-model="hostingProvider"
          v-if="!buildNew"
        />

        <base-input
          name="domain-name-registrar"
          label="Who is your domain name registrar?"
          v-model="domainRegistrar"
          v-if="!buildNew"
        />
        <!-- end of redesign information -->
      </form>
    </div>
</template>


<script>
import store from '@/store'

export default {
  computed: {
    bizName: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.bizName
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.bizName', value})
      }
    },
    bizDescription: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.bizDescription
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.bizDescription', value})
      }
    },
    buildNew: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.buildNew
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.buildNew', value})
      }
    },
    currentURL: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.currentURL
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.currentURL', value})
      }
    },
    hostingProvider: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.hostingProvider
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.hostingProvider', value})
      }
    },
    domainRegistrar: {
      get() {
        return store.state.forms.FormWebDevelopmentInfo.domainRegistrar
      },
      set(value) {
        store.commit('SET_FORM_ATTRIBUTE', {key: 'FormWebDevelopmentInfo.domainRegistrar', value})
      }
    },
  }
}
</script>
